<template>
  <nuxt-layout name="default">
    <template #toolBar>
      <m-carousel
        :items="carouselItems"
        :ui="{ item: 'basis-1/2 md:basis-1/3 lg:basis-1/4' }"
      >
      </m-carousel>
    </template>
    <div>
      <ul>
        <li v-for="group in homeGroups">
          <group-item-box
            :header="group.Header"
            :items="group.Items"
            :URL="group.URL"
          >
          </group-item-box>
        </li>
      </ul>
    </div>
  </nuxt-layout>
</template>

<script setup lang="ts">
import MCarousel from '@/components/carousel/MCarousel.vue';
import { mapMovieItems } from '~/composables/mapping/use-mapping-movie';
import type { Group } from '~/entities/models/group';
import type { Movie } from '~/entities/models/moive';

definePageMeta({
  layout: false,
});

const {
  data: groups,
  error,
  pending,
} = await useFetch<Group[]>('/api/app-components/home-group-items', {
  transform(groups) {
    let res = groups.map((group) => ({
      ...group,
      URL: `/danh-sach${generateUrlFromText(group.Header)}`,
      Items: mapMovieItems<Movie>(group.Items),
    }));
    return res;
  },
});
const homeGroups = useState('homeGroups', () =>
  groups.value?.slice(1, groups.value.length)
);
const carouselItems = useState(
  'homeCarouselItems',
  () => groups.value?.[0]?.Items
);

// const homeGroups = [
//   {
//     GroupType: 'home',
//     URL: '/danh-sach/phim-chieu-rap',
//     SortOrder: 1,
//     Header: 'Phim chiếu rạp',
//     Items: [
//       {
//         Name: 'EXHUMA: QUẬT MỘ TRÙNG MA',
//         OriginalName: 'Exhuma',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/exhuma-quat-mo-trung-ma/exhuma-quat-mo-trung-ma-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//     ],
//   },
//   {
//     GroupType: 'home',
//     URL: '/danh-sach/phim-bo-moi-cap-nhat',
//     SortOrder: 2,
//     Header: 'Phim bộ mới cập nhật',
//     Items: [
//       {
//         Name: 'EXHUMA: QUẬT MỘ TRÙNG MA',
//         OriginalName: 'Exhuma',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/exhuma-quat-mo-trung-ma/exhuma-quat-mo-trung-ma-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//     ],
//   },
//   {
//     GroupType: 'home',
//     URL: '/danh-sach/phim-le-moi-cap-nhat',
//     SortOrder: 3,
//     Header: 'Phim lẻ mới cập nhật',
//     Items: [
//       {
//         Name: 'EXHUMA: QUẬT MỘ TRÙNG MA',
//         OriginalName: 'Exhuma',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/exhuma-quat-mo-trung-ma/exhuma-quat-mo-trung-ma-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//       {
//         Name: 'Kung Fu Panda 4',
//         OriginalName: 'Kung Fu Panda 4',
//         Rating: 5,
//         Thumbnail:
//           'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
//         Quality: 'HD',
//         URL: '/phim/quat-mo-trung-ma',
//       },
//     ],
//   },
// ];
</script>

<style scoped></style>
