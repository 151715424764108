<template>
  <div class="group-item-box mt-4">
    <div class="text-lg flex justify-between items-end">
      <h1 class="box-header font-semibold text-2xl">{{ header }}</h1>
      <div class="text-md box-text-hover view-more">
        <nuxt-link :to="URL">{{ $t('ViewAll') }}</nuxt-link>
      </div>
    </div>
    <div
      class="item-wrapper grid grid-cols-2 gap-2 p-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      <div v-for="item in items">
        <movie-item :item="(item as any)"></movie-item>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MovieItem from '@/components/items/MovieItem.vue';
import type { GroupItem } from '@/entities/ui/group-item-box/group-item';
const { items, header } = withDefaults(
  defineProps<{
    items?: GroupItem[];
    header?: string;
    URL?: string;
  }>(),
  {
    //@ts-ignore
  }
);
</script>

<style scoped lang="scss">
.item-image {
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}

.group-item {
  background-color: var(--app-item-background-odd);
  padding: 2px;
  border-radius: 2px;
}
</style>
